import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { adaptBlogCategoryId, getBlogCategory, createBlogCategoryOptions, getBlogImage } from '@uq-helpers';
import { UqContainerV2, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqPage, UqTextHighlight, UqFilterBar, UqResourceCard, UqPagination, usePagination, UqRequestDemoBanner, UqFooterV2, UqHeroV2 } from '@uq-components';
import { useQueryParams } from '@uq-hooks';
import { ImageShape } from '../../models/media';

import * as styles from './index.module.scss';

const APP_BAR_HEIGHT_PX = 84;

const BASE_URL = '/blog';
const PAGE_SIZE = 9;

const FilterOptions = createBlogCategoryOptions();

UqBlogPage.propTypes = {
  data: PropTypes.shape({
    spotlightPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.string,
            categories: PropTypes.arrayOf(PropTypes.string),
            coverImage: ImageShape,
            publishDate: PropTypes.string,
            excerpt: PropTypes.string,
            slug: PropTypes.string,
            thumbnail: ImageShape,
            title: PropTypes.string,
          }),
        }),
      ),
    }),
    blogPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.string,
            categories: PropTypes.arrayOf(PropTypes.string),
            coverImage: ImageShape,
            publishDate: PropTypes.string,
            excerpt: PropTypes.string,
            slug: PropTypes.string,
            thumbnail: ImageShape,
            title: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
};

export default function UqBlogPage(props) {
  const spotlightPost = props.data.spotlightPost.edges[0];
  const blogPosts = props.data.blogPosts.edges;

  const breakpoint = useBreakpoint();
  const isSmMd = breakpoint.sm && breakpoint.downMd;

  const contentRef = useRef(null);

  const { params, updateParameter } = useQueryParams(['filter']);
  const selectedFilter = params['filter']

  const filteredResults = useMemo(() => {
    if (!selectedFilter || selectedFilter === 'all') {
      return blogPosts;
    }

    return blogPosts.filter(v => adaptBlogCategoryId(getBlogCategory(v.node)) === selectedFilter);
  }, [selectedFilter, blogPosts]);

  const {
    activePageNumber,
    currentPage,
    goTo,
    pages,
  } = usePagination(filteredResults, PAGE_SIZE);

  const scrollToContent = useCallback(() => {
    const currentScrollTop = window.scrollY;
    const containerY = contentRef.current.getBoundingClientRect().y;

    const scrollTop = currentScrollTop + containerY - APP_BAR_HEIGHT_PX;

    window.scrollTo({
      left: 0,
      top: scrollTop,
      behavior: 'smooth',
    });
  }, []);

  const selectPage = useCallback((pageNumber) => {
    goTo(pageNumber);
    scrollToContent();
  }, [goTo, scrollToContent]);

  const updateFilterSelection = useCallback((key) => {
    updateParameter('filter', key);
    selectPage(0);
  }, [updateParameter]);

  return (
    <UqPage>
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainerV2>
            <UqTextHighlight
              as="h1"
              className={styles.title}
            >
              {'unitQ **Blog**'}
            </UqTextHighlight>

            <UqResourceCard
              fill
              dark
              createdDate={spotlightPost.node.publishDate}
              description={spotlightPost.node.excerpt}
              thumbnail={getBlogImage(spotlightPost.node)}
              title={spotlightPost.node.title}
              type={getBlogCategory(spotlightPost.node)}
              url={`${BASE_URL}/${spotlightPost.node.slug}`}
              loadStyle="eager"
            />
          </UqContainerV2>
        </UqHeroV2>

        <UqContainerV2
          className={styles.blogsContainer}
          fill
        >
          <div ref={contentRef}>
            <article className={styles.filterBar}>
              <UqFilterBar
                autoScroll={false}
                options={FilterOptions}
                onOptionSelect={updateFilterSelection}
                value={selectedFilter || 'all'}
              />
            </article>

            <article className={classNames(styles.content, styles.blogsGrid)}>
              {currentPage.map((v, index) => (
                <UqResourceCard
                  key={index}
                  createdDate={v.node.publishDate}
                  thumbnail={isSmMd
                    ? v.node.coverImage
                    : getBlogImage(v.node)}
                  title={v.node.title}
                  type={getBlogCategory(v.node)}
                  url={`${BASE_URL}/${v.node.slug}`}
                />
              ))}
            </article>
            <UqPagination
              active={activePageNumber}
              pages={pages}
              onPageSelect={selectPage}
            />
            <UqRequestDemoBanner />
          </div>
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}

export const pageQuery = graphql`
  query {
    spotlightPost: allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      limit: 1
    ) {
      edges {
        node {
          author
          categories
          coverImage {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 1200,
            )
            url
          }
          thumbnail {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 800
            )
            url
          }
          publishDate(formatString: "ll")
          excerpt
          slug
          title
        }
      }
    },
    blogPosts: allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      skip: 1
    ) {
      edges {
        node {
          author
          categories
          coverImage {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 1200,
            )
            url
          }
          thumbnail {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              height: 600,
              placeholder: BLURRED
              width: 800,
            )
            url
          }
          publishDate(formatString: "ll")
          excerpt
          slug
          title
        }
      }
    }
  }
`;
